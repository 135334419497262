<template>
  <div class="result-main bg-background h-100">
    <search-header id="searchHeader" @slideMobileSidebar="slideMobileSidebar" :updatedQueryText="searchQuery"
      @translatingEnglishKeys="(val) => showTranslatingMsg = val" @retry="() => { if (!running) getData() }">
    </search-header>
    <div class="container add class-mobile">
      <div class="main-contant">
        <div class="list-holder">
          <div class="row">
            <!--<div v-if="results.length > 0"  class="col-lg-3 col-md-3 col-sm-3 col-xs-12 hebrewSidebar">-->
            <div class="col-lg-3 col-md-3 col-12 hebrewSidebar">
              <sidebar :queryText="searchQuery" :mobileSidebar="mobileSidebarVisible"
                @hideSidebar="changeSidebarVisibility"></sidebar>
            </div>

            <div class="col-md-9 col-12 pull-right hebrewSidebar mobile-padding-zero"
              :class="{ 'pt-3': showTranslatingMsg }">
              <div class="verses-text" id="verses-text">
                <div class="row result-alerts-top" v-for="(suggestion, suggestion_index) in suggestions"
                  :key="suggestion_index">
                  <div class="col-lg-12">
                    <!--<p v-if="!hebrew" class="f gray result-alert">We included close results in the results <span-->
                    <!--class="f blue result-alert-content">I'm not interested</span></p>-->
                    <!--<p v-if="hebrew" class="f gray result-alert">כללנו בתוצאות גם תוצאות קרובות <span class="f blue result-alert-content">איני מעוניין</span>-->
                    <!--</p>-->
                    <p class="f gray result-alert"><span v-if="suggestion_index === 0">{{ hebrew ? 'האם התכוונת ל' :
                        'Did you mean'
                    }}</span>
                      <a class="f blue result-alert-content" @click="replaceWithSuggestion(suggestion_index)">{{
                          suggestion.text
                      }}</a>
                      <em>({{ hebrew ? 'מציג' : 'gives' }} {{ suggestion.total }} {{ hebrew ? 'תוצאות' : 'results'
                      }})></em>
                    </p>
                    <hr class="result-alert-divider">
                  </div>
                </div>
                <!--<div v-for="(suggestion, suggestion_index) in suggestions" :key="suggestion_index">-->
                <!--<p><span v-if="suggestion_index === 0"><em>=======> Did you mean: </em></span>-->
                <!--<a href="#" @click="replaceWithSuggestion(suggestion_index)">{{ suggestion.text }}</a> <em>(gives {{ suggestion.total }} results)</em>-->
                <!--</p>-->
                <!--</div>-->
                <div class="row">
                  <div class="col-12">
                    <chapter-popup :chapter="chapterData" v-model="showChapterPopup"></chapter-popup>
                    <relevance-box v-if="$store.state.sortBy !== 'relevance' && page === 1 && !wasSortedByRelevance"
                      :hebrew="hebrew" :get-chapter="getChapter" :relevance-results="relevanceResults"
                      :running="running" :total="total" class="mb-3" />
                    <div class="d-none d-md-block">
                      <p v-if="!running && total === 0" class="noresults f black">
                        {{ hebrew ? 'לא נמצאו תוצאות' : 'NO RESULTS' }}
                      </p>
                      <div class="row top-info-row">
                        <div v-if="total > 0" class="col-sm-6">
                          <p class="f gray top-number-of-results">
                            <span>{{ hebrew ? 'מציג' : 'Showing' }}<strong> {{ (page - 1) * resultsPerPage + 1 }} - {{
                                Math.min(page * resultsPerPage, total)
                            }}</strong> {{ hebrew ? 'מתוך' : 'of' }} <strong>
                                {{ total }} </strong> {{ hebrew ? 'תוצאות' : 'Results' }} </span>
                          </p>
                        </div>
                        <div id="drop-down-sort" v-bind:class="[hebrew ? 'text-left' : 'text-right', 'col-sm-6']">
                          <div v-if="total > 0" class="drop-holder sort-drop-holder" @click="visible = !visible">
                            <p class="sort-drop-text d-inline-block bg-white px-2 pt-2 pb-1 mt-n1">
                              <span v-if="!hebrew">Sort: By {{ sortBy }} </span>
                              <span v-else> מיון: {{ sortByOptionsHebrew[sortBy] }} </span>
                              <i class="fas fa-angle-down mx-1"></i>
                            </p>
                            <transition name="slide-fade">
                              <div class="drop-down-sort" ref="dropdownMenu" v-if="visible">
                                <ul class="sort-list">
                                  <li class="sort-list-item"><a class="sort-link" @click="setSortBy('tanach order')">
                                      <span v-if="!hebrew">{{ SearchTypeString }} Order</span>
                                      <span v-else>סדר ה{{ SearchTypeString }}</span>
                                      <img v-if="sortBy.includes('order')" class="sort-list-icon"
                                        src="../../assets/ic_tick.png"></a></li>
                                  <li class="sort-list-item"><a class="sort-link" @click="setSortBy('relevance')">
                                      <span>{{ hebrew ? 'רלוונטיות' : 'Relevance' }}</span>
                                      <img v-if="sortBy === 'relevance'" class="sort-list-icon"
                                        src="../../assets/ic_tick.png"></a>
                                  </li>
                                </ul>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="show-result d-block d-md-none">
                      <div class="row no-gutters">
                        <p v-if="!running && total === 0" class="noresults f black d-inline-block px-3">
                          {{ hebrew ? 'לא נמצאו תוצאות' : 'NO RESULTS' }}
                        </p>
                        <div class="col-7">
                          <p v-if="results.length > 0"
                            class="f gray pull-left show-result-text text-body pt-4 px-2 mb-2"><strong> {{ (page - 1) *
                                resultsPerPage +
                                1
                            }} - {{ Math.min(page * resultsPerPage, total) }}</strong> {{ hebrew ? 'מתוך' : 'of' }}
                            <strong> {{ total }} </strong>
                            {{ hebrew ? 'תוצאות' : 'Results' }}
                          </p>
                        </div>
                        <div class="col-5 px-2 pt-4" v-if="results.length > 0">
                          <div v-if="!hebrew" class="text-right ml-n2">Sorted by {{ sortBy }}</div>
                          <div v-else class="text-left">מיון: {{ sortByOptionsHebrew[sortBy] }}</div>
                        </div>
                      </div>
                    </div>
                    <ul class="result-list text-right list-unstyled" dir="rtl" v-if="!running && results.length > 0">
                      <SingleResult v-for="(result, index) in results" class="result-li" :key="result._id"
                        :get-chapter="getChapter" :result="result" :analysis="analysis[index]"
                        @clicked="onClickChapter"></SingleResult>
                    </ul>
                    <div class="container" v-if="replacements.length > 0">
                      <h3 class="mt-2 mb-2">{{ hebrew ? 'חיפושים קרובים' : 'Similar searches' }}</h3>
                      <div class="mb-3">
                        <b-btn v-for="(replacement, index) in replacements" :key="index" size="lg"
                          class="rounded-pill f-narkis pt-1 pb-2 border border-secondary bg-white px-3"
                          :class="hebrew ? 'ml-2' : 'mr-2'" variant="outline" @click="replaceQuery(replacement.text)">{{
                              replacement.text
                          }}
                        </b-btn>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row result-alerts-bottom d-none">
                  <div class="col-lg-12">
                    <hr class="result-alert-divider">
                    <div class="clearfix"></div>
                    <div v-if="!hebrew">
                      <p class="f">Searches related to: <span>אריה</span></p>
                      <a href="#">
                        <p class="f blue result-alert-content">שבט יהודה</p>
                      </a>
                      <a href="#">
                        <p class="f blue result-alert-content">אריות</p>
                      </a>
                    </div>
                    <div v-if="hebrew">
                      <p class="f">חיפושים הקשורים ל: <span>אריה</span></p>
                      <a href="#">
                        <p class="f blue result-alert-content">שבט יהודה</p>
                      </a>
                      <a href="#">
                        <p class="f blue result-alert-content">אריות</p>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="pagination-holder">
                  <v-pagination id="pagination" v-if="!running && total > 0" v-model="page" next-icon="" prev-icon=""
                    :length="pageCount" totalVisible="8" @input="addAnimation"></v-pagination>
                  <div v-if="!running && total > 0" class="page-style d-block d-md-none">
                    <span>{{ hebrew ? 'עמוד' : 'Page' }} {{ page }}/{{ Math.ceil(total / resultsPerPage) }}</span>
                  </div>
                </div>
                <div v-if="this.results.length" @click="goToTop"
                  class="bg-white back-to-top text-center d-block d-md-none">
                  <i class="fa fa-angle-up" aria-hidden="true"></i>
                  <h3 class="my-0">{{ hebrew ? 'חזרה לראש העמוד' : 'GO TO TOP' }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <howPopup v-model="showHowItWorks" />
    <how-popup-hebrew v-model="showHowHeb" />
    <ServerFailedPopup v-if="showFailed" v-model="showFailed" />
    <Loader v-if="running"></Loader>
    <!--end mobile view-->
  </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import config from '../../config'
import SearchHeader from '../searchHeader.vue'
import Sidebar from './sidebar.vue'
import Loader from '../commonLoader'

import { mutationHelpers, NikudAndTeamim, stateHelpers } from '../../store'
import { makeQuery } from '../../queries'
import SingleResult from './SingleResult'
import ChapterPopup from './chapterPopup.vue'
import { SearchType } from '../../searchConfig.js'
import howPopup from '@/components/howPopup.vue'
import HowPopupHebrew from '@/components/howPopupHebrew.vue'
import ServerFailedPopup from '@/components/ServerFailedPopup'
import RelevanceBox from '@/components/result/RelevanceBox'
axios.defaults.timeout = 120000 // two minutes
axios.defaults.headers['Content-type'] = 'text/plain; charset=UTF-8'

export default {
  components: {
    RelevanceBox,
    SingleResult,
    ChapterPopup,
    Sidebar,
    SearchHeader,
    howPopup,
    HowPopupHebrew,
    Loader,
    ServerFailedPopup
  },
  name: 'Result',
  data() {
    return {
      showTranslatingMsg: false,
      visible: false,
      searchQuery: null,
      show: true,
      results: [],
      relevanceResults: [],
      page: 1,
      pageCount: 5,
      total: 0,
      running: false,
      chapterData: {},
      mobileSidebarVisible: false,
      suggestions: [],
      expanded: false,
      analysis: [],
      relevanceAnalysis: [],
      NikudAndTeamim,
      showChapterPopup: false,
      showHowItWorks: false,
      showHowHeb: false,
      wasSortedByRelevance: false,
      replacements: []
    }
  },
  computed: {
    SearchType() {
      return SearchType
    },
    showFailed: {
      get() {
        return this.$store.state.serverFailed
      },
      set(value) {
        this.setServerFailed(value)
      }

    },
    SearchTypeString() {
      return SearchType.Bible ? (this.hebrew ? 'תנ"ך' : 'Tanach') : (SearchType.Talmud ? (this.hebrew ? 'תלמוד' : 'Talmud') : (this.hebrew ? 'קורפוס' : 'Corpus'))
    },
    hebrew() {
      // return this.$route.name.includes('hebrew')
      return this.$settings.hebrew
    },
    sortByOptionsHebrew() {
      return SearchType.Bible ? {
        'relevance': 'רלוונטיות',
        'tanach order': 'סדר התנ"ך'
      } : (SearchType.Talmud ? {
        'relevance': 'רלוונטיות',
        'talmud order': 'סדר התלמוד'
      } : {
        'relevance': 'רלוונטיות',
        'corpus order': 'סדר הקורפוס'
      })
    },
    ...stateHelpers
  },
  methods: {
    goToTop() {
      $('html,body').animate({ scrollTop: 0 }, 'slow')
    },
    onDocumentClick(event) { //hide sort drop-down if clicked outside of drop-down
      let currentElement = event.target
      let listAreaClicked = false
      while (currentElement) {
        if (currentElement.classList.contains('drop-down-sort') || 
        currentElement.classList.contains('drop-holder')) {
          listAreaClicked = true
          break;
        }
        currentElement = currentElement.parentElement        
      }
      if (this.visible && !listAreaClicked) {
        this.visible = false       
      }        
    },
    removeNikud(str) {
      return str.replace(/[\u05Be]/g, " ").replace(/[^א-ת ]/g, "")
    },
    checkRelevance() {
      if (this.$route.query.order === 'relevance') {
        this.setSortBy('relevance')
      } else {
        this.setSortBy('tanach order')
      }
    },
    checkPagination() {
      window.addEventListener('popstate', () => {
        window.popStateDetected = true
        let searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has('page')) {
          this.page = parseInt(searchParams.get('page'))
        }
      })
    },
    openHowItWorks() {
      if (this.hebrew) {
        this.showHowHeb = true
      } else {
        this.showHowItWorks = true
      }
    },
    onClickChapter(value) {
      this.showChapterPopup = value
    },
    replaceQuery(query) {
      this.searchQuery = query
      this.$gtag('use_suggestion', { search_term: this.searchQuery })
    },
    getRelatedSearches() {
      axios.post(config.SERVER + '/alternatives', this.searchQuery).then(response => {
        let suggestionJsons = []
        response.data.forEach(alts_for_one_word => { // note this does not filter out "negated" terms
          suggestionJsons = suggestionJsons.concat(
            alts_for_one_word.matches.map(match => JSON.stringify(match))
          )
        })
        suggestionJsons = [...new Set(suggestionJsons)] // we started with Jsons so this would work
        const suggestions = suggestionJsons.map(suggestionJson => JSON.parse(suggestionJson))
        this.replacements = []
        suggestions.map(item => {
          let queryParams = makeQuery()
          queryParams.query = this.removeNikud(this.searchQuery).replace(item.toReplace, item.replacement)
          axios.post(config.SERVER + '/search', queryParams).then(response => {
            if (response.data.total > 0) {
              this.replacements.push({ /// object, so it's clear that "searchQuery = replacement.text"
                text: queryParams.query // is assigning TEXT; the ONLY non-text assignment to searchQuery
              }) ///////////////////////// is the original assignment "searchQuery: null" in "data ()"
            }
          })
        })
      })
    },
    async tryRelevance(searchPromise) {
      this.relevanceResults = []
      this.relevanceAnalysis = []
      let queryParams = makeQuery()
      if (queryParams.sort === '') return
      // there's no reason to try relevance with single word queries
      /// if (!/[א-ת].* .*[א-ת]/.test(queryParams.query)) return

      queryParams.sort = ''
      const relevanceResults = (await axios.post(config.SERVER + '/search', Object.assign({
        from: 0,
        size: 2
      }, queryParams))).data.hits
      const results = (await searchPromise).data.hits
      const first5 = results.slice(0, 5)
      //set relevant results if exact match for any of the results
      const matchedResults = relevanceResults.filter(x =>
        this.removeNikud(x.highlight[0].text).includes(this.removeNikud(this.searchQuery))
      )
      if (matchedResults.length > 0 && !this.removeNikud(this.results[0]?.highlight[0].text).includes(this.removeNikud(this.searchQuery))) {
        this.relevanceResults = relevanceResults
      } else {
        const distinctHits = relevanceResults.filter(hit => !first5.some(result => result.xmlId === hit.xmlId))
        // don't show relevance if all the hits appear on the top of the page anyway
        if (distinctHits.length === 0) return

        // Are the relevance hits "better"?
        const getMatchTypes = list => {
          const result = { 'phrase': 0, 'phrase_slop': 0 }
          for (let matchType of list.map(hit => hit.match_type)) {
            result[matchType] = (result[matchType] || 0) + 1
          }
          return result
        }
        // Let's look at the first 5 results
        const first5Types = getMatchTypes(first5)
        const relevanceTypes = getMatchTypes(relevanceResults)
        // if there are enough phrase matches in the first 5 and enough phrase_slop matches, then relevance seems boring
        if (relevanceTypes['phrase'] <= first5Types['phrase'] && relevanceTypes['phrase_slop'] <= first5Types['phrase_slop'])
          return
        this.relevanceResults = relevanceResults
      }
      // textAnalysis is not displayed in the current version
      // axios.post(config.SERVER + '/textAnalysis', Object.assign({
      //   from: 0,
      //   size: this.detectmob() ? 2 : 3
      // }, queryParams)).then(response => {
      //   this.relevanceAnalysis = response.data
      // })
    },
    async getData() {
      if (!this.searchQuery) {
        return
      }
      if (this.noResultsPossible) {
        this.results = []
        this.total = 0
        return
      }
      this.suggestions = []
      const vueObj = this
      this.$gtag('search', { search_term: this.searchQuery })
      vueObj.running = !vueObj.mobileSidebarVisible
      const searchRequest = axios.post(config.SERVER + '/search', Object.assign({
        from: (this.page - 1) * this.resultsPerPage,
        size: this.resultsPerPage
      }, makeQuery()))
      if (this.$store.state.sortBy !== 'relevance')
        this.tryRelevance(searchRequest)
      searchRequest.then(response => {
        vueObj.running = false
        vueObj.results = response.data.hits
        vueObj.total = response.data.total
        vueObj.pageCount = Math.ceil(response.data.total / vueObj.resultsPerPage)
        if (vueObj.page > vueObj.pageCount) {
          vueObj.page = 1
        }
        this.loadAnalysis()
        this.getRelatedSearches()
      })
        .catch(error => {
          console.log(error)
          this.setServerFailed(true)
          this.running = false
        })
    },
    loadAnalysis() {
      this.running = true
      axios.post(config.SERVER + '/textAnalysis', Object.assign({
        from: (this.page - 1) * this.resultsPerPage,
        size: this.resultsPerPage
      }, makeQuery())).then(response => {
        this.analysis = response.data
        this.running = false
      }).catch(error => {
        console.log(error)
        this.setServerFailed(true)
        this.running = false
      })
    },
    callDidYouMean(responseData) {
      axios.post(config.SUGGESTER + '/suggest', {
        query: this.searchQuery,
        search_api_response: JSON.stringify(responseData)
      }).then(response => {
        if (response.status === 200) {
          let allData = JSON.parse(response.data)
          console.log('allData - first parse')
          console.log(allData)
          if (typeof allData === 'string') {
            allData = JSON.parse(allData.replace(/(\d+)L/g, '$1').replace(/"/g, '\\"').replace(/u'/g, '"').replace(/'/g, '"'))
            console.log('allData - second parse')
            console.log(allData)
          }
          if (allData.new_total > allData.old_total) {
            allData.new_queries.forEach(newQuery => {
              console.log(Object.keys(newQuery)[0])
              var suggestion = {
                text: Object.keys(newQuery)[0],
                total: allData.new_total,
                api_response: JSON.parse(newQuery[Object.keys(newQuery)[0]])
              }
              this.suggestions.push(suggestion)
            })
          }
        }
      }).catch(error => {
        console.log(error)
        //this.showFailed = true
        this.setServerFailed(true)
      })
    },
    replaceWithSuggestion(suggestionIndex) {
      this.results = this.suggestions[suggestionIndex].api_response
      this.total = this.suggestions.length
      // this.pageCount = 1
      // this.page = 1
      this.searchQuery = this.suggestions[suggestionIndex].text
    },
    getChapter(id, unitType) {
      const vueObj = this
      vueObj.chapterData = {}
      axios.post(config.SERVER + '/chapter', {
        query: this.query,
        lexemes: this.lexemesState,
        related: this.relatedState,
        id: id,
        unit_type: unitType
      })
        .then(response => {
          vueObj.chapterData = response.data
          if (id.includes('Bavli')) {
            vueObj.chapterData.xmlId = id
          } else {
            // emphasize the pasuk, but if it's not a pasuk match, don't emphasize any pasuk
            // test with a regex - if it ends with .2.3 or similar, it's a pasuk match
            vueObj.chapterData.pasuk = /\.\d+\.\d+/.test(id) ? parseInt(id.substr(id.lastIndexOf('.') + 1)) : 0
          }
          this.showChapterPopup = true
        }).catch(error => {
          console.log(error)
          this.setServerFailed(true)
        })
    },
    newQuery() {
      if (this.sortBy === 'relevance')
        this.wasSortedByRelevance = true
      if (this.query === '') {
        return
      }
      this.searchQuery = this.$route.query.text
      this.page = 1
      this.results = []
      this.total = 0
      this.getData()
    },
    pageToResultNum(page) {
      return (page - 1) * this.resultsPerPage + 1
    },
    changeBodyClass() {
      document.body.className = 'rest'
    },
    resultPageChanging() {
      $('.logo-header').addClass('logo-header-for-result')
      $('.mobile-header-row ').addClass('rest-mobile-header-row')
      $('.main-text').addClass('main-text-for-result')
      $('.mobile-link').addClass('mobile-link-for-result')
    },
    addAnimation() {
      // change page #
      var urlString = window.location.href
      if (urlString.indexOf('page') !== -1) {
        var url = new URL(urlString)
        var queryString = url.search
        var searchParams = new URLSearchParams(queryString)
        searchParams.set('page', this.page)
        url.search = searchParams.toString()
        urlString = url.toString()
      } else {
        urlString += (urlString.match(/[?]/g) ? '&' : '?') + 'page=' + this.page
      }
      window.history.replaceState({ path: urlString }, '', urlString)
      let list = document.getElementsByClassName('pagination__navigation')
      for (let i = 0; i < list.length; i++) {
        list[i].classList.add('hoverable')
        list[i].classList.add('button')
        list[i].classList.add('raised')
        list[i].innerHTML = '<div class="anim"></div>'
      }
    },
    slideMobileSidebar() {
      this.mobileSidebarVisible = true
    },
    changeSidebarVisibility(val) {
      this.mobileSidebarVisible = val
    },
    ...mutationHelpers
  },
  updated() {
    this.resultPageChanging()
  },
  mounted () {
    this.iOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
    if (this.iOS) {
      document.body.classList.add('iphone')
    }
    this.checkPagination()
    this.checkRelevance()
    this.newQuery()
    // this.changeBodyClass()
    this.resultPageChanging()
    this.addAnimation()
    document.addEventListener('click', this.onDocumentClick) //handle click on document if sort dropdown open
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  },
  watch: {   
    page() {
      this.getData()
    },
    'searchStateUpdates.counter': 'newQuery'
  }
}
</script>
<style scoped lang="scss">
.mobile-header {
  padding: 6px 8px;

  img {
    width: 34px;
    height: auto;
  }

  a {
    text-decoration: none;
  }
}

.rest-btn-top {
  display: block;
}

.top-info-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 991px) {
    margin-right: -5px;
    margin-left: -5px;
  }
}

.top-number-of-results {
  margin: 0 0 13px;
}

.result-alert {
  margin-bottom: 0;
}

.result-alert-divider {
  border-top: 1px solid #D8D8D8;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 13%;
  float: left;
}

.he .result-alert-divider {
  float: right;
}

.drop-down-sort {
  display: block;
  right: 16px;
  top: 100%;
  position: absolute;
  z-index: 999;
  width: 213px;
  border-radius: 2px;
  background-color: #fbfbfb;
  border: solid 1px #e5e5e5;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.he .drop-down-sort {
  left: 16px;
  right: auto
}

.sort-drop-text {
  margin-bottom: 0;
  cursor: pointer;
}

.sort-list {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  display: block;
  text-align: left;
}

.sort-list-item {
  display: block;
  padding: 0;
  width: 100%;
  min-height: inherit;
  background-color: #fbfbfb;
  border-top: solid 1px #d8d8d8;
}

.he .sort-list-item {
  text-align: right;
}

.sort-list-icon {
  display: inline-block;
  float: right;
  position: relative;
  top: 4px;
}

.he .sort-list-icon {
  float: left;
}

.sort-list-item:hover {
  background-color: #fff;
}

.sort-list-item:first-child {
  border: none;
}

.sort-link {
  display: block;
  padding: 9px 14px 6px;
  cursor: pointer;
  color: #656565;
  font-size: 16px;
}

.sort-link:hover {
  color: #007EE5;
}

.verses-text {
  overflow: hidden;
  position: relative;
  padding: 0;
  min-height: 350px;
}

.result-list {
  overflow: hidden;
  line-height: 25px;
  font-size: 20px;
  padding: 0;
  font-weight: normal;

  @media screen and (max-width: 991px) {
    margin-bottom: 12px;
  }
}

.noresults {
  padding: 8px 0;
}

.result-li {
  border-radius: 2px;
  background-color: #ffffff;
  border: solid 1px #e5e5e5;
  margin-bottom: 8px;
  position: relative;

}

/*.result-li p {*/
/*margin: 0;*/
/*}*/
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s;
}

.slide-fade-enter {
  transform: translateY(10px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.he .hebrewSidebar {
  float: right
}

.mobile-sidebar-btn {
  display: none;
}

@media screen and (min-width: 992px) {
  .noresults {
    height: 64px;
    border: none;
    margin: 0 0 10px;
    padding: 12px 10px 12px;
    background-color: #fbfbfb;
    border-bottom: 1px solid #c8c8c8;
    font-size: 25px;
    font-weight: 500;
  }
}

@media screen and (max-width: 991px) {
  .rest-btn-top {
    display: block;
  }

  .mobile-padding-zero {
    padding: 0;
  }

  .result-alert {
    padding: 15px 0 0;
    margin-bottom: 0;
  }

  .result-alert-divider {
    width: 100%;
  }

  .result-alert-content {
    display: block;
  }

  .noresults {
    text-align: left;
    width: calc(100% - 50px);
  }

  .he .noresults {
    text-align: right;
  }

  .result-li {
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

  .show-result {
    border: none;
  }

  .verses-text {
    position: relative;
    z-index: 999999
  }

  .container.add.class-mobile {
    position: relative;
    /* 1, so footer doesn't overlap content */
    z-index: 1;
  }

  .collapse-section {
    display: none !important;
  }

  .mobile-sidebar-btn {
    display: block;
    float: right;
    margin-top: -8px;
  }

  .he .mobile-sidebar-btn {
    float: left;
  }
}

.back-to-top {
  padding: 3px 0 6px 0;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;

  h3 {
    position: relative;
    top: -4px;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .hebrewSidebar {
    padding-right: 10px !important;
  }

  .he .hebrewSidebar {
    padding-right: 10px !important;
  }
}

@media screen and (max-width: 767px) {
  .result-main {
    min-height: calc(100vh - 108px) !important;
  }

  .iphone .result-main {
    min-height: calc(100vh - 123px) !important;
  }
}
</style>
